.list-component {

    .list-wrapper {
        display: flex;
        flex-direction: column;

        .article {
            // display: flex;

            a {
                text-decoration: none;
            }

            .article-container {
                // display: flex;
                margin: 40px 0;
                display: flex;
                flex-direction: row;
                color: #000000;
                // width: 560px;
                word-wrap: break-word;

                img {
                    width: 600px;
                    height: 320px;
                    margin-right: 20px;
                    object-fit: cover;
                }

                h1 {
                    // color: #000;
                    width: 560px;
                    font-weight: 400;
                    max-height: 70px;
                    margin-top: 0;
                    color: #000;
                    font-family: Raleway;
                    word-wrap: break-word;
                    padding-bottom: 74px;
                    overflow: hidden;
                }

                .descr {
                    margin-top: 10px;
                    font-size: 18px;
                    width: 560px;
                    max-height: 190px;
                    overflow: hidden;
                }
            }

            .divider {
                margin-top: 40px;
                // margin: 0 5px;
                border-bottom: 1px solid #555555;
                width: 1200px;
            }
        }

        .fetch-articles {
            // border: 1px solid black;
            border: none;
            color: #fff;
            // fon
            border-radius: 4px;
            background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
            font-family: Raleway;
            font-size: 22px;
            // margin-top: 20px;
            margin: 20px auto;
            cursor: pointer;
            width: 150px;
            height: 35px;
            align-self: center;
            font-weight: 300;
            letter-spacing: 1.1px;
        }
    }
}


@media (max-width: 1200px) {
    .list-component {
        .list-wrapper {
            .article {
                .article-container {

                    // width: ;
                    img {
                        width: 400px;
                        height: 240px;
                    }

                    h1 {
                        width: 500px;

                    }

                    .descr {
                        width: 500px;
                        max-height: 108px;
                    }
                }

                .divider {
                    width: 920px;
                }
            }
        }
    }
}


@media (max-width: 991px) {
    .list-component {
        .list-wrapper {
            .article {
                .article-container {

                    // width: ;
                    img {
                        width: 370px;
                        height: 210px;
                    }

                    h1 {
                        width: 350px;

                    }

                    .descr {
                        width: 350px;
                        max-height: 87px;
                    }
                }

                .divider {
                    width: 750px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .list-component {
        margin: 0 5px;
        display: flex;
        // justify-items: center;
        // align-items: center;
        // width: 300px;

        .list-wrapper {

            width: 100%;
            // width: 100%;


            .article {
                display: flex;
                flex-direction: column;
                width: auto;

                .article-container {
                    display: flex;
                    flex-direction: column;

                    a {
                        width: auto;

                        img {
                            height: 100%;
                            width: 100%;
                            margin: 0;
                            aspect-ratio: 5 / 3;
                        }
                    }

                    h1 {
                        // display: none;
                        width: 100%;
                        max-height: 75px;
                        padding: 0;
                        margin: 10px 0;

                    }

                    .descr {
                        width: 100%;
                        // display: none;
                    }
                }

                .divider {
                    width: 100%;
                }
            }
        }
    }
}