.carousel-component {
    display: flex;
    justify-content: center;
    display: none;


    .slick-slider {
        height: 110px;
        width: 1150px;
        margin-bottom: 100px;

        .slick-track {
            height: 100px;
        }

        .slick-slide {
            width: 182px;
            margin: 0 6px;


            // .hidden {
            //     opacity: 0;
            //     z-index: 0
            // }
            // .wrapper .image:hover + .hidden{

            // }

            .wrapper {
                width: 180px;

                height: 110px;



                &:hover {
                    .image {
                        opacity: 0;
                    }

                    .descr {
                        opacity: 1;

                    }
                }

                .image {
                    // position: absolute;
                    transition: 200ms linear;

                    img {
                        width: 180px;
                        height: 110px;
                        object-fit: cover;

                    }

                    &:hover {
                        // visibility: hidden;
                        color: transparent
                    }



                    // &:hover+.descr {
                    //     display: block;
                    //     opacity: 1;
                    // }
                }


                .descr {

                    // position: absolute;
                    // visibility: hidden;
                    word-break: break-all;
                    // display: none;
                    position: absolute;
                    width: 180px;
                    height: 98px;
                    color: #000;
                    font-family: Raleway;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    letter-spacing: 1.33px;
                    opacity: 0;
                    // -webkit-box-orient: vertical;
                    // -webkit-line-clamp: 6;
                    // padding-bottom: 20px;
                    overflow: hidden;

                    &:hover {
                        opacity: 1;
                    }



                }
            }



            a {
                color: #000;
                font-family: Raleway;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                letter-spacing: 1.33px;
                word-wrap: break-word;
                text-decoration: none;
            }

            .title {
                width: 180px;
                word-break: break-all;
                height: 84px;
                overflow: hidden;
            }

            .mobile-descr {
                display: none;
            }

        }

        .slick-arrow {


            // background-color: silver;
            background: var(--silver, linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.10) 100%));
            height: 100%;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                content: '';
            }

            &:hover {
                // background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.70) 100%);
                background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));

                .in {
                    // background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.70) 100%);
                    background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

        }
    }




    .out {
        z-index: 9998;
        font-weight: 350;
        transform: scale(2.3, 3);
        position: absolute;
        // position: absolute;
        // color: aqua;
        // color: aqua
        background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    .in {
        background: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 9999;
        position: absolute;
        font-weight: 100;
        left: 0px;
        color: white;
        // height: 20px;
        // width: 20px;
        // display: block;
        // position: absolute;
        height: 100%;
        width: 100%;


    }
}



@media (max-width: 1200px) {
    .carousel-component {
        // display: flex;
        display: none;
        justify-content: space-around;

        .slick-slider {
            width: 920px;

            .slick-list {
                height: 190px;
            }

            .slick-slide {
                width: 222px;
                margin: 0 5px;

                // width: 250px;
                .wrapper {
                    // width: 268px;

                    .image {
                        img {
                            width: 222px;
                        }
                    }

                    .descr {
                        width: 222px;
                    }
                }

                .title {
                    width: 222px;
                }
            }

        }

    }
}

@media (max-width: 991px) {
    .carousel-component {
        .slick-slider {
            width: 700px;

            .slick-list {
                height: 190px;
            }

            .slick-slide {
                width: 225px;
                margin: 0 5px;

                // width: 250px;
                .wrapper {
                    // width: 268px;

                    .image {
                        img {
                            width: 225px;
                        }
                    }

                    .descr {
                        width: 225px;
                    }
                }

                .title {
                    width: 225px;
                }
            }

        }
    }
}

@media (max-width: 767px) {
    .carousel-component {
        display: block;
        // max-width: 700px;
        // width: auto;
        // margin: 0 5px;

        .slick-slider {
            // max-width: 700px;
            width: 100%;


            height: 305px;


            .slick-dots {
                top: 395px;

                // left: 100px;
                li {
                    button:before {
                        // width: 100px;
                        // height: 100px;
                        // font-size: 24px;
                        // height: 50px;
                        // width: 50px;
                        border: 1px solid #000;
                        border-radius: 50%;
                        height: 10px;
                        width: 10px;
                        color: transparent;
                        opacity: 1;
                    }
                }

                .slick-active {

                    // background-color: yellow;
                    button:before {
                        background-color: #000;
                    }
                }

            }

            .slick-list {
                height: 380px;
                // height: 500px;


            }

            .slick-slide {
                // max-width: 700px;
                width: auto;
                margin: 0;

                .wrapper {
                    padding: 5px;
                    width: auto;
                    pointer-events: none;
                    height: 220px;

                    .image {
                        width: auto;

                        img {
                            width: 100%;
                            // max-width: 700px;
                            pointer-events: none;
                            height: 200px;
                        }
                    }

                    .descr {
                        display: none;
                    }
                }

                .title {
                    // display: none;
                    width: auto;
                    font-weight: 400;
                    height: auto;
                    max-height: 65px;
                    // padding: 10px;
                    padding: 0 4px;
                }

                .mobile-descr {
                    margin-top: 10px;
                    display: block;
                    // width: 180px;
                    word-break: break-all;
                    max-height: 92px;
                    overflow: hidden;
                    padding: 0 4px 2px 4px;
                }
            }
        }

    }
}


@media (max-width: 425px) {}