.block-component {
    letter-spacing: 2.24px;
    width: 1200px;

    a {
        text-decoration: none;
        color: #000;
    }

    h1 {
        background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: drop-shadow(0px 1.5px 1px rgba(0, 0, 0, 0.2));
        font-weight: 400;
    }

    h2 {
        font-size: 28px;
        font-weight: 300;
    }

    span {
        font-size: 24px;

    }

    .carousel {


        .title {
            // font-weight: 300;
            // font-family: Raleway;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .main-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // margin-top: 10px;

        .featured {
            width: 640px;

            // width: 50%;
            h2 {
                a {
                    width: fit-content;
                }
            }

            a {

                display: flex;
                flex-direction: row;

                // justify-content: space-between;


                img {
                    width: 350px;
                    height: 240px;
                    object-fit: cover;
                    margin-right: 20px;
                }

                .container {
                    display: flex;
                    flex-direction: column;
                    width: 260px;
                    // word-wrap: break-word;

                    .title {
                        color: #000;
                        font-family: Raleway;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 110%;
                        /* 24.2px */
                        letter-spacing: 1.1px;
                        // height: 100%;
                        // width: 300px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        padding-bottom: 1px;

                        // max-height: 100px;
                        // padding-bottom: 1px;
                        // overflow: hidden;
                    }

                    .descr {
                        margin-top: 10px;
                        color: #000;
                        font-family: Raleway;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: 110%;
                        /* 15.4px */
                        letter-spacing: 0.7px;
                        word-wrap: break-word;
                        // max-height: 120px;
                        // overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 9;
                        padding-bottom: 1px;
                        overflow: hidden;
                    }
                }
            }

        }

        .popular {
            width: 540px;
            // display: flex;
            // flex-direction: column;

            h2 {
                a {
                    width: fit-content;
                }
            }

            .container {

                width: 260px;
                height: 240px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;


                a {

                    &:first-child {
                        margin-right: 20px;
                    }

                    img {
                        width: 260px;
                        height: 150px;
                        object-fit: cover;
                        margin: 0;
                        // object-position: 25% 25%;
                    }

                    .title {
                        width: 260px;
                        margin-top: 20px;
                        color: #000;
                        font-family: Raleway;
                        font-size: 19px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 109.9%;
                        /* 20.881px */
                        letter-spacing: 0.95px;
                        word-wrap: break-word;
                        // height: 60px;
                        // overflow: hidden;
                        // padding-bottom: 63px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        padding-bottom: 1px;
                        overflow: hidden;
                    }
                }
            }

        }
    }

    .bottom-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .videos {

            width: 640px;

            .container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                a {
                    flex: 1;

                    img {
                        width: 200px;
                        height: 110px;
                    }

                    .title {
                        color: #000;
                        font-family: Raleway;
                        font-size: 19px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 109.9%;
                        /* 20.881px */
                        letter-spacing: 0.95px;
                        width: 200px;
                        word-wrap: break-word;
                        // height: 80px;
                        // overflow: hidden;
                        // padding-bottom: 83px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 4;
                        padding-bottom: 1px;
                        overflow: hidden;
                    }
                }

            }
        }

        .external {
            width: 540px;

            .container {

                display: flex;
                flex-direction: row;

                .wrapper {
                    width: 260px;
                    height: 200px;
                    border: 1px solid #000000;
                    display: flex;
                    flex-direction: column;
                    // padding: 5px;

                    &:first-child {
                        margin-right: 20px;
                    }

                    .source {
                        width: 258px;
                        padding: 0 6px;

                        // background-color: #000000;
                        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
                        color: #fff;
                        text-align: center;
                        height: 19px;
                    }

                    .title {

                        color: #000;

                        font-family: Raleway;
                        font-size: 19px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 110.9%;

                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        padding-bottom: 1px;
                        overflow: hidden;
                        letter-spacing: 0.95px;
                        // justify-content: center;
                        // margin: 0;
                        word-wrap: break-word;
                        height: 65px;
                        text-align: center;
                        margin: 1px 5px;

                    }

                    .description {
                        color: #000;
                        font-family: Raleway;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: 110%;
                        /* 15.4px */
                        margin-top: 2px;
                        letter-spacing: 0.7px;
                        word-wrap: break-word;
                        // height: 80px;
                        margin: 1px 5px;
                        // max-height: 78px;
                        // overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 5;
                        padding-bottom: 1px;
                        overflow: hidden;

                    }

                    .link {
                        // background-color: #000000;
                        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
                        color: #FFF;
                        // text-align: center;
                        align-items: center;
                        justify-content: center;
                        font-family: Raleway;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        letter-spacing: 1.2px;
                        display: flex;
                        width: 100%;
                        margin-top: auto;
                        // bottom: 0;
                        // align-self: flex-end;
                        // justify-self: flex-end;

                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .block-component {
        width: 980px;

        .main-content {
            .featured {
                width: 530px;

                a {
                    img {
                        width: 300px;
                        height: 190px;
                    }

                    .container {
                        width: 210px;

                        .title {
                            width: 210px;
                            max-height: 88px;
                            font-size: 20px;
                        }

                        .descr {
                            max-height: 88px;

                        }
                    }
                }
            }

            .popular {
                width: 430px;

                .container {
                    height: 200px;

                    a {
                        width: 200px;

                        &:first-child {
                            margin-right: 10px;
                        }

                        img {
                            width: 200px;
                            height: 110px;
                        }

                        .title {
                            width: 200px;
                        }
                    }
                }
            }
        }

        .bottom-content {
            .videos {
                width: 530px;

                .container {
                    a {
                        width: 170px;


                        img {
                            width: 170px;
                            height: 100px;
                        }

                        .title {
                            width: 170px;
                        }
                    }
                }

            }

            .external {
                width: 430px;

                .container {

                    .wrapper {

                        width: 210px;

                        height: 185px;

                        &:first-child {
                            margin-right: 10px;
                        }

                        .source {
                            width: 100%;
                            overflow: auto;
                        }

                        .title {
                            // width: 10px;
                            width: 200px;
                            height: 60px;
                        }

                        .description {
                            // margin-top: 10px;
                            width: 200px;
                            height: 60px;
                        }
                    }
                }

            }
        }
    }
}



@media (max-width: 991px) {
    .block-component {
        width: 750px;

        .main-content {
            .featured {
                width: 450px;
                margin-right: 10px;

                a {
                    img {
                        width: 250px;
                        height: 160px;
                        margin-right: 8px;
                    }

                    .container {
                        width: 182px;

                        .title {
                            width: 182px;
                            max-height: 67px;
                        }
                    }

                }
            }

            .popular {
                width: 300px;

                .container {
                    height: 165px;
                    width: 300px;

                    a {
                        width: 145px;

                        img {
                            width: 145px;
                            height: 80px;
                        }

                        .title {
                            width: 145px;
                        }
                    }

                }
            }

        }

        .bottom-content {
            .videos {
                width: 450px;
                margin-right: 0px;



                .container {

                    a {
                        // &:nth-child(3) {
                        //     margin: 0;
                        // }

                        width: 135px;
                        margin-right: 10px;

                        img {
                            width: 135px;
                            height: 80px;
                        }

                        .title {
                            width: 135px;
                        }
                    }
                }
            }

            .external {
                width: 300px;

                display: flex;
                flex-direction: column;

                .container {
                    .wrapper {
                        width: 145px;

                        .title {
                            width: 100%;
                            padding: 0 5px;
                            margin: 0;
                        }

                        .description {
                            width: 100%;
                            padding: 2px;
                        }
                    }
                }
            }
        }
    }

}



@media (max-width: 767px) {
    .block-component {

        width: auto;
        margin: 0 10px;

        .main-content {
            flex-direction: column;
            align-items: center;

            .featured {
                width: 100%;
                margin: 0;

                h2 {
                    a {
                        display: flex;
                        flex-direction: row;
                    }
                }

                a {
                    flex-direction: column;

                    .container {
                        width: 100%;


                        .title {
                            margin-top: 10px;
                            width: 100%;
                            font-weight: 400;
                        }

                        .descr {
                            font-size: 16px;
                            font-weight: 300;
                        }
                    }



                    img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 5 / 3;
                        // width: 300px;
                        // height: 180px;
                        margin: 0;
                        object-fit: cover;
                    }
                }
            }

            .popular {
                width: 100%;



                .container {
                    display: block;
                    flex-direction: column;
                    width: auto;
                    // height: 500px;
                    height: auto;
                    // margin-top: 200px;

                    a {


                        &:first-child {
                            margin: 0;
                        }

                        width: 100%;

                        img {

                            width: 100%;
                            // height: 180px;
                            height: 100%;
                            aspect-ratio: 5 / 3;

                            // &:first-child {
                            //     margin: 0;
                            // }
                        }

                        .title {
                            margin-top: 5px;
                            width: 100%;
                            margin-bottom: 20px;
                            font-weight: 400;
                        }

                        .descr {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .bottom-content {
            flex-direction: column;
            align-items: center;

            .videos {
                width: 100%;
                align-items: center;
                // flex-direction: row;


                .container {
                    display: flex;
                    width: 100%;
                    // align-items: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    // height: 500px;

                    a {
                        margin-top: 10px;
                        // width: 100%;
                        margin: 0;
                        height: auto;
                        flex: auto;

                        margin-top: 10px;

                        img {
                            width: 100%;
                            height: auto;
                            aspect-ratio: 5 / 3;
                        }

                        .title {
                            width: 100%;
                            font-weight: 400;
                            font-size: 16px;
                        }

                        &:first-child {
                            margin-top: 0;
                            width: 100%;

                            // width: 400px;
                            // width: 100%;
                            .title {
                                font-size: 20px;
                            }



                        }

                        &:nth-child(2) {
                            width: 50%;


                            padding-right: 5px;

                        }

                        &:nth-child(3) {
                            width: 50%;
                            padding-left: 5px;
                        }
                    }

                }


            }

            .external {
                width: 100%;
                // height: 100%;

                .container {
                    .wrapper {
                        // width: 100%;
                        width: 50%;
                        height: 100%;

                        .title {
                            padding-bottom: 63px;
                        }

                        .description {
                            height: 79px;
                            margin-bottom: 2px;
                        }
                    }
                }
            }


        }
    }
}


@media (max-width: 425px) {
    .block-component {
        .bottom-content {
            .external {
                width: 100%;

                .container {
                    // margin-top: 10px;
                    flex-direction: column;

                    .wrapper {
                        margin-top: 10px;
                        width: 100%;
                        height: 220px;

                        &:first-child {
                            margin-top: 0;
                        }

                        .source {
                            font-size: 16px;
                        }

                        .title {
                            width: 100%;
                            font-size: 20px;
                            padding-bottom: 67px;
                        }

                        .description {
                            width: 100%;
                            font-size: 16px;
                            padding-bottom: 0;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}