.auth-page {

    h1 {
        text-align: center;
        filter: drop-shadow(0px 1.5px 1px rgba(0, 0, 0, 0.2));


        font-family: Montserrat;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        background: linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    input {
        padding: 10px;
        margin-top: 15px;
        border: 1px solid #000000;
        border-radius: 5px;
        width: 330px;
        height: 40px;
        color: #555;
        font-family: Raleway;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
    }

    .wrapper {
        form {


            display: flex;
            flex-direction: column;
            align-items: center;

            .input-field {
                width: 330px;
                height: 165px;

                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .input-title {
                // margin-top: 50px;
                height: 80px;
            }
        }

        .code-title {
            color: #000;
            text-align: center;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 1.4px;
            width: 350px;
            margin-bottom: 30px;
            height: 50px;
        }

        .terms {
            margin-top: 15px;
            text-align: center;
            font-size: 20px;

            a {
                text-decoration: none;
                color: #326891;
            }
        }

        .recoverCode {
            width: 330px;
            height: 72px;
            flex-shrink: 0;
        }
    }

    .key {
        margin-top: 30px;
        cursor: pointer;
    }

    .additional {
        color: #000;
        text-align: center;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 1.4px;
        background-color: transparent;

    }

    .main {
        width: 190px;
        height: 40px;
        border-radius: 4px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
        color: #fff;
        font-size: 22px;
        text-align: center;
        // text-justify: center;
        padding: 5px;
    }

    // display: flex;

    // flex-direction: column;

}

@media (max-width: 767px) {
    .auth-page {
        input {
            width: 300px;
            height: 40px;
        }
    }
}