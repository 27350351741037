.App {
  width: 1200px;
  margin: auto;
  // display: flex;
  padding-bottom: 30px;
  align-items: flex-start;
  // height: calc(100vh - 165px);
  min-height: calc(100vh - 165px);
}

@media (max-width: 1200px) {
  .App {
    width: 980px;
  }
}

@media (max-width: 991px) {
  .App {
    width: 750px;
  }
}

@media (max-width: 767px) {
  .App {
    width: auto;
  }
}