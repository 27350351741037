.search-page {
    a {
        text-decoration: none;
    }

    .article {

        .article-container {
            margin: 40px 0;
            display: flex;
            flex-direction: row;
            color: #000000;

            img {
                width: 600px;
                height: 320px;
                margin-right: 20px;
                object-fit: cover;
            }

            h1 {
                color: #000;
            }

            .descr {
                margin-top: 10px;
                font-size: 18px;
            }
        }

        .divider {
            margin-top: 40px;
            // margin: 0 5px;
            border-bottom: 1px solid #555555;
            width: 1200px;
        }
    }

    .fetch-articles {
        // border: 1px solid black;
        border: none;
        color: #fff;
        // fon
        border-radius: 4px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
        font-family: Raleway;
        font-size: 22px;
        margin-top: 20px;
        cursor: pointer;
        width: 150px;
        height: 35px;
        align-self: center;
        font-weight: 300;
        letter-spacing: 1.1px;
    }
}