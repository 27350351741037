.create-article-page {
    // margin-top: 40px;
    padding: 10px;

    h1 {
        margin: 30px 0 0 0;
        color: #000;
        // text-align: center;
        font-family: Raleway;
        font-size: 54px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.78px;
    }

    form {
        margin-top: 25px;

        input {
            margin: 0 10px;
            border: 1px solid #4f4f4f;
            border-radius: 2px;

            &:first-child {
                margin: 0 10px 0 0;
            }
        }

        .image-upload {
            border: none;
            // width: 175px;
            // 
        }

        .image-upload::before {
            content: 'Upload main image';
            border: 1px solid #4f4f4f;
            cursor: pointer;
            padding: 2px;
            border-radius: 3px;
            color: #4f4f4f;



        }

        .image-upload::-webkit-file-upload-button {
            visibility: hidden;
            width: 0;

        }
    }

    .text-editor {
        margin-top: 20px;
    }

    .type {
        margin-top: 17px;
    }

    .category {
        margin-top: 13px;
    }

    .success {
        margin-top: 10px;
        // color: #7edd1e;
        color: #2E9CB4;
    }

    button {
        margin-top: 16px;
    }
}