.admin-page {
    position: relative;
    width: 100%;
    // display: flex;

    .header {
        z-index: 9000;
        height: 50px;
        width: 200vw;
        position: absolute;
        // left: 23.45%;

        transform: translateX(-100vh);

        border: 1px solid black;

        padding: 5px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));




    }

    .header-content {
        height: 50px;
        width: 100%;
        position: absolute;
        z-index: 9999;
        display: flex;

        align-items: center;
        justify-content: space-between;

        h1 {
            // margin-right: 100px;'
            margin: 0;
            color: #ACDDED;
            font-weight: 600;
        }

        .logout {
            color: #ACDDED;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .sidebar {
        margin: 0;
        padding: 0;
        width: 200px;
        background-color: #ACDDED;
        position: absolute;
        // height: 100%;
        // overflow: auto;
        display: flex;
        flex-direction: column;
        // position: fixed;
        // top: 60px;
        top: 50px;
        height: 100vh;


        a,
        button {
            font-size: 16px;
            border: none;
            margin-top: 10px;
            border-radius: 4px;
            background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
            color: #FFF;
            text-align: center;
            font-family: Raleway;
            font-style: normal;
            font-weight: 200;
            line-height: normal;
            letter-spacing: 1.54px;
            width: 190px;
            height: 25px;
            padding: 2px;
            cursor: pointer;
            text-decoration: none;
            align-self: center;

        }

    }

    .content {
        // width: 400px;
        position: absolute;
        top: 70px;
        display: flex;
        flex-direction: column;
        margin-left: 220px;
        // overflow: auto;
        // margin-top: 20px;

        .articles-container {
            margin-top: 10px;

            .article {
                display: flex;

                img {
                    width: 250px;
                    height: 200px;
                    margin-right: 20px;
                }

                .title {

                    font-weight: 700;
                    font-size: 28px;
                }

                .descr {
                    margin-top: 10px;
                }

                .info {
                    margin-top: 10px;
                }
            }

            .article-control {

                a,
                button {
                    font-size: 16px;
                    border: none;
                    margin-top: 10px;
                    border-radius: 4px;
                    background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 1.54px;
                    // width: 190px;
                    // height: 25px;
                    margin-right: 10px;
                    padding: 4px;
                    cursor: pointer;
                    text-decoration: none;
                    align-self: center;

                }
            }

        }

        .user {
            border-bottom: 1px solid #000;
            margin-top: 10px;
            padding: 2px;

            div {
                margin-top: 4px;
                cursor: pointer;
            }
        }
    }
}