.privacyAndTerms-page {
    line-height: 2;
    // margin-top: 40px;
    padding: 10px;

    h1 {
        font-weight: 500;
    }

    h2 {
        font-weight: 400;
    }
}

.about-us {
    h1 {

        color: #000;
        text-align: center;
        font-family: Raleway;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 2.8px;
    }

    // text-align: center;
    width: 1000px;
    margin: 0 auto;
    padding: 10px;
}

@media (max-width: 991px) {
    .about-us {
        width: 100%;
    }
}

.contact-us {
    padding: 10px;

    h1 {
        text-align: center;
        font-weight: 500;
    }

    // margin-top: 40px;

    div {
        margin-top: 20px;
    }


}

@media (max-width: 767px) {
    .privacyAndTerms-page {
        a {
            word-wrap: break-word;
        }
    }

}