.article-page {
    margin-top: 30px;

    h1 {
        font-size: 55px;
        letter-spacing: 2.75px;
        font-weight: 400;
        word-wrap: break-word;
    }

    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 19px;
    }

    .category {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        border: 1px solid #555555;
        border-right: none;
        border-left: none;
        align-items: center;
        padding: 5px 0;
        font-size: 18px;
        color: #555555;

        .divider {
            margin: 0 5px;
            border-left: 1px solid #555555;
            height: 14px;
        }
    }

    .main-image {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {

            width: 1200px;
            height: 670px;
            object-fit: cover;
        }
    }

    .content {
        font-size: 20px;
        font-weight: 300;

        img {
            max-width: 1000px;
            // max-height:;
        }
    }
}

.video-page {
    h1 {
        font-weight: 400;
    }

    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 19px;
    }





    iframe {
        margin-top: 20px;
        width: 500px;
        height: 300px;
    }

    .descr {
        margin-top: 20px;
        font-size: 20px;
    }

    .category {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        border: 1px solid #555555;
        border-right: none;
        border-left: none;
        align-items: center;
        padding: 5px 0;
        font-size: 18px;
        color: #555555;
    }

    .divider {
        margin: 0 5px;
        border-left: 1px solid #555555;
        height: 14px;
    }
}

.comments {
    display: flex;
    flex-direction: column;
    font-weight: 300;

    .comments-title {
        margin-top: 40px;
        font-size: 40px;
        letter-spacing: 2.2px;
    }

    .textarea-head {
        margin-top: 20px;
        display: flex;
        flex-direction: row;

        .avatar {
            width: 50px;
            height: 50px;
            margin: 82px 20px 0 0;

            .avatar-border {
                margin-right: 20px;
                border-radius: 50%;
                background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                height: 54px;
                width: 54px;
                position: absolute;
            }

            span {
                position: absolute;
                border-radius: 50%;
                background-color: #B3BDC0;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                top: 2px;
                right: -8px;
                margin-right: 10px;
                font-weight: 400;
                font-size: 26px;
                color: #ffffff;
                z-index: 2;
            }
        }

        .textarea-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .title {
                font-size: 16px;
                letter-spacing: 1.12px;
            }

            .subtitle {
                margin-top: 5px;
                font-size: 16px;
                color: #326891;
                font-weight: 200;
                letter-spacing: 1.12px;
                text-decoration: none;
            }




            textarea {
                border: 1px solid black;
                margin-top: 20px;
                resize: none;
                width: 1140px;
                z-index: 9990;
                font-size: 18px;
                font-family: "Raleway", sans-serif;
                font-weight: 300;
                padding: 7px;
                height: 58px;
                border-radius: 4px;


                &:focus {
                    outline: none;
                }
            }

            .invalid-input {
                border: 1px solid red;

            }

            .post-comment {
                border: none;
                color: #fff;
                // fon
                border-radius: 4px;
                background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
                font-family: Raleway;
                font-size: 22px;
                // margin-top: 10px;
                cursor: pointer;
                width: 150px;
                height: 35px;
                align-self: center;
                margin-right: 59px;
                // margin-left: 32px;
                font-weight: 300;
                letter-spacing: 1.1px;
            }
        }

    }

    .comment-container {
        .comment {
            margin: 35px 0 50px 0;

            display: flex;
            flex-direction: row;

            .avatar {
                width: 50px;
                height: 50px;
                // margin: 82px 20px 0 0;
                margin-right: 15px;

                .avatar-border {
                    margin-right: 20px;
                    border-radius: 50%;
                    background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                    height: 54px;
                    width: 54px;
                    position: absolute;
                }

                span {
                    position: absolute;
                    border-radius: 50%;
                    background-color: #B3BDC0;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    top: 2px;
                    right: -8px;
                    margin-right: 10px;
                    font-weight: 400;
                    font-size: 26px;
                    color: #ffffff;
                    z-index: 2;
                }
            }

            .comment-details {


                .item-details {
                    display: flex;
                    flex-direction: row;
                    // margin-top: 5`0px;


                    .user-details {
                        // text-align: end;
                        align-items: flex-end;
                        align-self: flex-end;

                        .role {
                            color: #00C2FF;
                            font-size: 12px;
                        }

                        .name {
                            font-size: 18px;
                            margin-right: 10px;
                        }

                    }

                    .date {
                        color: #808080;
                        font-size: 12px;
                        // margin-top: 14px;
                        align-self: flex-end;
                        margin-bottom: 5px;
                    }

                    .delete-comment {
                        display: flex;

                        // text-align: end; 
                        span {
                            align-self: flex-end;
                            font-weight: 200;
                        }

                        background-color: transparent;
                        border: none;
                        margin-left: 10px;
                        padding: 0;
                        cursor: pointer;
                        // margin-top: 20px;

                        color: #000;
                        // justify-self: flex-end;
                    }
                }

                .content {
                    margin-top: 2px;
                    max-width: 1150px;
                }

            }

            .content {
                font-size: 16px;
                letter-spacing: 1.1px;
            }
        }
    }


    .fetch-comments {
        // border: 1px solid black;
        border: none;
        color: #fff;
        // fon
        border-radius: 4px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
        font-family: Raleway;
        font-size: 22px;
        margin-top: 20px;
        cursor: pointer;
        width: 150px;
        height: 35px;
        align-self: center;
        font-weight: 300;
        letter-spacing: 1.1px;
    }
}

@media (max-width: 1200px) {
    .article-page {
        .main-image {
            img {
                width: 900px;
            }
        }

        .content {
            img {
                max-width: 900px;
                // max-height:;
            }
        }
    }

    .comments {
        .textarea-head {
            .textarea-container {
                textarea {
                    width: 900px;
                }
            }
        }
    }
}


@media (max-width: 991px) {
    .article-page {
        .main-image {
            img {
                width: 700px;
            }
        }

        .content {
            img {
                max-width: 700px;
                // max-height:;
            }
        }
    }

    .comments {
        .textarea-head {
            .textarea-container {
                textarea {
                    width: 650px;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .article-page {
        padding: 0 10px;

        h1 {
            font-size: 24px;
            letter-spacing: 1.2px;

        }

        .main-image {

            width: auto;

            img {
                width: 100%;
                height: 100%;
                aspect-ratio: 5 / 3;
            }
        }

        .content {
            img {

                width: 100%;
                // max-height:;
            }
        }

        .category {
            justify-content: center;
        }
    }

    .video-page {
        iframe {
            margin-top: 20px;
            width: 100%;
            height: 300px;
        }

        .category {
            justify-content: center;
        }
    }

    .comments {
        padding: 5px;

        .comments-title {
            margin-top: 20px;
            text-align: center;
        }

        .textarea-head {
            flex-direction: column;

            .avatar {
                // justify-self: center;
                align-self: center;
                margin-top: 0;
                margin-left: 9px;


            }

            .textarea-container {
                // flex-direction: column-reverse;
                padding: 0 10px;

                .title {
                    text-align: center;
                }

                .subtitle {
                    text-align: center;
                }

                textarea {
                    margin-top: 10px;
                    width: 100%;

                }

                .live-characters {
                    margin-top: 5px;
                    align-self: center;
                }

                .post-comment {
                    margin: 10px 0 0 0;
                    height: 30px;
                }
            }
        }
    }
}