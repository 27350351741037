// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Neucha&family=Raleway&display=swap');


// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap%27');


@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Neucha&family=Raleway:wght@100;300&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Neucha&family=Raleway:ital,wght@0,100;0,200;0,300;1,200&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Neucha&family=Raleway:wght@100&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  // sans-serif;
  font-family: 'Neucha', cursive;
  font-family: 'Raleway', sans-serif;

  // font-family: "";
  // letter-spacing: 1.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}