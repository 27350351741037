.users-page {
    display: flex;

    .main-title {
        color: #000;
        margin-top: 40px;
        // text-align: center;
        font-family: Raleway;
        font-size: 55px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 3.85px;
    }

    .user-wrapper {
        margin-right: 120px;

        .user-container {
            width: 800px;



            .title {

                color: #000;
                font-family: Raleway;
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 2.24px;
                margin-top: 25px;
            }


            .name-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // margin-top: 20px;


                .name {
                    .change-name {

                        .input-controls {
                            display: flex;

                            input {

                                border: 1px solid #000;
                                width: 400px;
                                height: 40px;
                                flex-shrink: 0;
                                font-size: 24px;
                                padding: 5px;
                                margin: 5px 5px 10px 0;
                                border-radius: 4px;
                            }
                        }

                        input {

                            border: 1px solid #000;
                            width: 400px;
                            height: 40px;
                            flex-shrink: 0;
                            font-size: 24px;
                            padding: 5px;
                            margin: 5px 5px 0 0;
                            border-radius: 4px;
                        }

                        .btn-done {
                            cursor: pointer;
                            display: flex;
                            align-items: center;

                            span {
                                font-size: 34px;
                            }
                        }

                        .btn-cancel {
                            display: flex;
                            align-items: center;
                            cursor: pointer;

                            span {
                                font-size: 34px;
                            }
                        }
                    }

                    .prev-name {
                        color: #000;
                        font-family: Raleway;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: normal;
                        letter-spacing: 2.24px;
                    }
                }

                .btn {
                    margin-top: 55px;

                }
            }

            .email-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // margin-top: 10px;

                .email {
                    color: #000;
                    font-family: Raleway;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 2.24px;
                }

                .btn {
                    margin-top: 7px;
                    text-decoration: none;
                    padding: 5px;
                    border: 1px solid #000;
                }
            }

            .password-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .password {


                    span {
                        font-weight: 200;
                        color: #000;
                        font-size: 32px;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 2.24px;
                    }
                }

                .btn {
                    text-decoration: none;
                    // padding: 2px;
                    padding-top: 5px;
                    border: 1px solid #000;
                }
            }

            .signout {
                margin-top: 40px;
                border: 1px solid #2E9CB4;
                border-radius: 4px;
                color: #2E9CB4;
                text-align: center;
                padding: 6px;
                font-family: Raleway;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.54px;
                width: 210px;
                height: 40px;
                cursor: pointer;
            }


        }
    }

    .publisher-wrapper {
        display: flex;
        flex-direction: column;

        .link {
            // margin-top: 20px;
            text-decoration: none;
            padding: 5px;
            border: 1px solid #000;
            width: 270px;
            margin-top: 54px;


            // margin: 42px;
            &:nth-child(3) {
                margin-top: 74px;
            }

            &:nth-child(4) {
                margin-top: 72px;
            }
        }


        .mobile {
            display: none;
        }

    }

    .btn {
        margin-top: 10px;
        align-self: flex-start;
        border-radius: 4px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
        color: #FFF;
        text-align: center;
        font-family: Raleway;
        font-size: 22px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        letter-spacing: 1.54px;
        width: 210px;
        height: 40px;
        padding: 0;
        cursor: pointer;
    }
}


@media (max-width: 1200px) {
    .users-page {
        .user-wrapper {
            margin-right: 100px;

            .user-container {
                width: 600px;
            }
        }
    }
}


@media (max-width: 991px) {
    .users-page {
        display: flex;
        flex-direction: column;

        .user-wrapper {
            .user-container {
                .signout {
                    display: none;
                }
            }
        }

        .publisher-wrapper {
            .mobile {
                display: block;
            }

            display: flex;


            .signout {
                align-self: center;
                // text-align: center;
                margin-top: 40px;
                border: 1px solid #2E9CB4;
                border-radius: 4px;
                color: #2E9CB4;
                text-align: center;
                padding: 6px;
                font-family: Raleway;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.54px;
                width: 210px;
                height: 40px;
                cursor: pointer;
            }
        }
    }
}


@media (max-width: 767px) {
    .users-page {
        padding: 10px;

        .user-wrapper {
            margin: 0;

            .user-container {
                width: 100%;

                .main-title {
                    margin-top: 20px;
                    font-weight: 300;
                    font-size: 36px;
                }

                .title {
                    margin-top: 20px;
                }

                .name-container {
                    // margin-top: ;
                    align-items: flex-start;
                    flex-direction: column;

                    .name {

                        .prev-name {
                            margin: 10px 0;
                            font-size: 24px;
                        }
                    }

                    .btn {
                        margin-top: 0px;
                        width: 160px;
                        height: 26px;
                        font-size: 16px;

                    }
                }

                .email-container {
                    align-items: flex-start;
                    flex-direction: column;

                    .email {
                        font-size: 24px;
                        overflow-wrap: break-word;
                        height: 100%;
                        margin: 10px 0;
                    }

                    .btn {
                        margin-top: 0px;
                        width: 160px;
                        height: 26px;
                        font-size: 16px;
                        padding-top: 3px;
                    }
                }

                .password-container {
                    align-items: flex-start;
                    flex-direction: column;

                    .password {

                        span {
                            margin: 10px 0;
                            font-size: 24px;
                        }
                    }

                    .btn {
                        margin-top: 0px;
                        width: 160px;
                        height: 26px;
                        font-size: 16px;
                        padding-top: 3px;
                    }
                }

                display: flex;
                flex-direction: column;

                .signout {

                    align-self: center;
                }


            }
        }

        .publisher-wrapper {
            .main-title {
                font-size: 36px;
            }
        }


    }
}