@import '../../styles/variables.scss';


.header-component {
    position: sticky;
    width: 100%;

    z-index: 9997;
    top: 0px;
    // background-color: aqua;
    background-color: rgba(255, 255, 255, 0.9);
    // box-shadow: 0 4px 6px -6px #222;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.20));
    box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(5px);




    .wrapper {
        height: 140px;
        width: 1200px;


        margin: 0 auto;

        .container {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            align-items: center;

            .hamburger {
                display: none;
            }




            .logo-container {
                text-decoration: none;


                .logo {
                    // position: absolute;
                    background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: Montserrat;
                    font-size: 54px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    filter: drop-shadow(0px 1.5px 1px rgba(0, 0, 0, 0.2));
                    top: 20px;
                    text-decoration: none;

                    span {


                        filter: drop-shadow(0px 1.5px 1px rgba(0, 0, 0, 0.2));
                        background: linear-gradient(180deg, #8AB3D9 0%, #000 74.22%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: Montserrat;
                        font-size: 54px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }


                }

                .sub-logo {
                    color: #000;
                    font-family: Raleway;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 0%;
                    letter-spacing: 0.84px;
                    text-align: end;
                    margin-right: 5px;
                    // position: relative;
                    // top: 30px;
                    // left: 325px;
                }
            }

            .service-menu {
                display: flex;
                // height: 60px;
                text-align: center;
                align-items: center;

                .search {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;

                    // height: 10px;
                    input {
                        color: #555;
                        text-align: center;
                        font-family: Raleway;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        letter-spacing: 1.4px;
                        margin-right: 5px;
                    }

                    .search-cap {
                        font-weight: 300;
                        margin-right: 15px;
                        cursor: pointer;
                    }

                    span {
                        // text-align: end;
                        // margin-top: 10px;
                        font-weight: 200;
                        cursor: pointer;
                    }
                }

                .login {



                    outline: 1px solid #acdded;
                    outline-offset: 2px 10px;
                    border-radius: 5px;
                    padding: 2px 8px;

                    // color: #acdded;
                    color: #2E9CB4;
                    // text-colo: #2E9CB4

                }

                a {
                    text-decoration: none;
                    // font-weight: 300;
                }

                .avatar {
                    width: 50px;
                    height: 50px;
                    margin: 0 20px 0 0;

                    .avatar-border {
                        margin-right: 20px;
                        border-radius: 50%;
                        background: var(--blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                        height: 54px;
                        width: 54px;
                        position: absolute;
                    }

                    span {
                        position: absolute;
                        border-radius: 50%;
                        background-color: #B3BDC0;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;
                        top: 2px;
                        right: -8px;
                        margin-right: 10px;
                        font-weight: 400;
                        font-size: 26px;
                        color: #ffffff;
                        z-index: 2;
                    }
                }

            }
        }

        .links {
            margin-top: 14px;
            display: flex;
            flex-direction: row;
            letter-spacing: 1.33px;
            width: 1200px;
            justify-content: space-between;
            // display: flex;
            align-items: center;

            .divider {
                // display: ;
                border-left: 1px solid #000;
                height: 20px;
                // margin: 0;
                // position: absolute;
                // left: 50%;
                // margin: 0 13px 0 13.27px;
            }

            .main {
                height: 40px;
            }

            a {
                color: #000;
                text-decoration: none;
                font-size: 19px;
                font-weight: 300;
            }
        }

        .menu {
            overflow: auto;
            position: fixed;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            // display: none;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            top: 64px;
            left: -100%;
            width: 100%;
            height: 100vh;
            padding: 20px 15px;
            background-color: rgba(255, 255, 255, 0.9);
            -webkit-transition: 0.3s all;
            transition: 0.3s all;

            input {
                color: #555;
                text-align: center;
                font-family: Raleway;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 1.4px;
                margin-right: 5px;
                border-radius: 4px;
            }

            a {
                color: #000;
                text-decoration: none;
                font-size: 19px;
                font-weight: 300;
                margin-top: 10px;
            }

            .divider {
                margin-top: 10px;
                border-bottom: 1px solid #000;
                width: 160px;
            }

            .login {
                margin-top: 20px;
                border: 1px solid #2E9CB4;
                border-radius: 4px;
                color: #2E9CB4;
                text-align: center;
                padding: 2px;
                font-family: Raleway;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.54px;
                width: 220px;
                height: 30px;
                cursor: pointer;
            }
        }
    }
}


// @media (max-width: 1400px) {
//     .header-component {
//         .wrapper {
//             // background-color: #000;
//             width: 1000px;

//             .links {
//                 width: 1000px;

//                 a {
//                     font-size: 14px;
//                 }
//             }
//         }

//     }
// }


@media (max-width: 1200px) {
    .header-component {
        .wrapper {
            // background-color: #000;
            max-width: 980px;


            .links {
                width: 980px;

                a {
                    font-size: 14px;
                }
            }
        }

    }
}

@media (max-width: 991px) {
    .header-component {
        .wrapper {
            // background-color: #000;
            width: 750px;

            .container {
                .service-menu {
                    input {
                        width: 180px;
                        height: 30px;
                    }
                }
            }

            .links {
                width: 750px;

                // align-items: ;
                // text-align: start;
                // justify-self: start;
                // flex-direction: column;
                justify-content: space-between;
                // justify-content:center;



                :nth-child(1) {
                    // background-color: #000;
                    width: 70px;
                }

                :nth-child(3) {
                    width: 50px;
                }

                :nth-child(5) {
                    width: 30px;
                }

                :nth-child(13) {
                    width: 50px;
                }

                a {

                    // justify-self: start;
                    // text-align: start;
                    width: 80px;
                }
            }
        }

    }
}

@media (max-width: 767px) {
    .header-component {
        height: 64px;

        .wrapper {
            width: auto;
            height: 64px;

            .container {
                padding: 0 0 0 10px;
                display: flex;
                align-items: center;
                // height: 64px;


                .logo-container {
                    margin-top: 16px;

                    // margin-top: ;
                    .logo {
                        // margin-top: 4px;
                        font-size: 28px;

                        span {
                            // top: 20px;

                            font-size: 28px;
                        }

                    }

                    .sub-logo {
                        // margin-bottom: 10px;
                        margin-top: 4px;
                        font-size: 10px;
                        margin-right: 3px;
                    }
                }



                .service-menu {
                    display: none;
                }

                .hamburger {

                    display: block;
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    width: 26px;
                    height: 12px;

                    span {
                        display: block;
                        height: 2px;
                        width: 100%;
                        background-color: #000;
                        margin-bottom: 3px;
                        -webkit-transition: 0.5s all;
                        transition: 0.5s all
                    }

                    &_active {


                        span {
                            margin-bottom: -2px;

                            &:nth-child(1) {
                                -webkit-transform: translateY(3px) rotate(-45deg);
                                transform: translateY(3px) rotate(-45deg)
                            }

                            &:nth-child(2) {
                                display: none
                            }

                            &:nth-child(3) {
                                -webkit-transform: translateY(3px) rotate(45deg);
                                transform: translateY(3px) rotate(45deg)
                            }

                        }
                    }
                }


            }

            .links {
                display: none;
            }



            .menu_active {
                left: 0;
                display: flex;

            }
        }
    }
}