.external-page {

    .external-wrapper {
        display: flex;
        flex-direction: column;

        .article {
            // display: flex;



            .article-container {
                // display: flex;
                margin: 45px 0;
                display: flex;
                flex-direction: column;
                color: #000000;
                word-wrap: break-word;


                h1 {
                    // margin-top: 10px;
                    color: #000;
                    width: 900px;
                    font-weight: 500;
                    margin: 0;
                    max-height: 120px;
                    overflow: hidden;
                }

                .date {
                    // margin-top: 50px;
                    margin-bottom: 12px;
                }

                .descr {

                    margin-top: 15px;
                    font-size: 24px;
                    max-height: 120px;
                    overflow: hidden;
                    // width: 900px;
                }

                a {
                    // border: 1px solid black;
                    // width: 200px;
                    font-size: 18px;
                    color: #2E9CB4;
                    margin-top: 15px;
                    text-decoration: none;
                    // box-shadow: #000;
                    // font-weight: 700;
                    cursor: pointer;
                }
            }

            .divider {
                // margin-top: 30px;
                // margin: 0 5px;
                border-bottom: 1px solid #555555;
                width: 1200px;
            }
        }

        .fetch-articles {
            // border: 1px solid black;
            border: none;
            color: #fff;
            // fon
            border-radius: 4px;
            background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
            font-family: Raleway;
            font-size: 22px;
            // margin-top: 20px;
            margin: 20px auto;
            cursor: pointer;
            width: 150px;
            height: 35px;
            align-self: center;
            font-weight: 300;
            letter-spacing: 1.1px;
        }
    }
}


@media (max-width: 1200px) {
    .external-page {
        .external-wrapper {
            .article {
                .article-container {
                    h1 {
                        width: 950px;
                    }

                    .descr {
                        width: 950px;
                    }
                }

                .divider {
                    width: 950px;
                }
            }
        }

    }
}

@media (max-width: 991px) {
    .external-page {
        .external-wrapper {
            .article {
                padding: 0 10px;

                .article-container {

                    h1 {
                        width: auto;
                    }

                    .descr {
                        width: auto;
                    }
                }

                .divider {
                    width: auto;
                }
            }
        }

    }
}