.category-page {
    display: flex;
    flex-direction: column;

    h1 {
        color: #000;
        text-align: center;
        font-family: Raleway;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 2.8px;
    }

    h2 {
        color: #000;
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 2.24px;
    }

    .wrapper {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;

        .main-article {
            width: 630px;
            height: 240px;
            display: flex;
            margin: 0px 5px 75px 0;



            img {
                width: 350px;
                height: 240px;
                margin-right: 15px;
                object-fit: cover;
            }

            .title {
                color: #000;
                font-family: Raleway;
                font-size: 22px;
                font-style: normal;
                font-weight: 300;
                line-height: 110%;
                /* 24.2px */
                letter-spacing: 1.1px;
                max-height: 100px;

                padding-bottom: 1px;
                overflow: hidden;

                width: 260px;
            }

            .descr {
                color: #000;
                font-family: Raleway;
                font-size: 14px;
                font-style: normal;
                font-weight: 200;
                line-height: 110%;
                /* 15.4px */
                margin-top: 2px;
                letter-spacing: 0.7px;
                word-wrap: break-word;
                // max-height: 120px;
                // overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 8;
                padding-bottom: 1px;
                overflow: hidden;
                // margin: 1px 5px;
                margin-top: 10px;
            }
        }

        .sub-article {
            width: 260px;
            height: 240px;
            margin-left: 20px;

            a {
                margin: 0 auto;
            }

            img {
                width: 260px;
                height: 160px;
                object-fit: cover;
            }

            .title {
                width: 260px;
                margin-top: 4px;
                color: #000;
                font-family: Raleway;
                font-size: 19px;
                font-style: normal;
                font-weight: 300;
                line-height: 109.9%;
                /* 20.881px */
                letter-spacing: 0.95px;
                word-wrap: break-word;
                height: 80px;
                overflow: hidden;
                padding-bottom: 83px;
            }
        }

        .article {
            width: 200px;
            height: 200px;
            margin: 10px 50px 0 0;
            // margin-right: 20px;

            img {
                width: 200px;
                height: 110px;
                object-fit: cover;
            }

            .title {
                color: #000;
                font-family: Raleway;
                font-size: 19px;
                font-style: normal;
                font-weight: 300;
                line-height: 109.9%;
                /* 20.881px */
                letter-spacing: 0.95px;
                width: 200px;
                word-wrap: break-word;
                height: 80px;
                overflow: hidden;
                padding-bottom: 83px;
            }
        }

        .no-right-margin {
            margin: 0;
        }
    }

    a {
        text-decoration: none;

    }

    // a {
    //     margin: 0 auto;
    // }

    button {

        color: #000;
        font-family: Raleway;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 109.9%;
        /* 43.96px */
        letter-spacing: 2px;
        background-color: transparent;
        border: none;
        margin: 20px auto 0 auto;
        cursor: pointer;
        display: flex;
    }


}

@media (max-width: 1200px) {
    .category-page {
        .wrapper {
            width: 980px;

            .main-article {
                width: 480px;
                height: 210px;
                margin-right: 10px;

                img {
                    width: 280px;
                    height: 210px;
                }

                .title {
                    width: 200px;
                }

                .descr {
                    width: 200px;
                    height: 110px;
                }
            }

            .sub-article {
                width: 220px;
                height: 200px;

                img {
                    width: 220px;
                    height: 120px;
                }

                .title {
                    width: 220px;
                }
            }

            .article {
                width: 175px;
                margin: 10px 24px 0 0;

                img {
                    width: 175px;
                }

                .title {
                    width: 175px;
                }
            }

            .no-right-margin {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 991px) {
    .category-page {
        .wrapper {
            width: 750px;

            .main-article {
                width: 400px;
                height: 210px;
                margin-right: 4px;


                img {
                    width: 220px;
                    height: 160px;
                    margin-right: 4px;
                }

                .title {
                    width: 180px;
                    max-height: 68px;
                    font-size: 20px;
                }

                .descr {
                    width: 180px;
                    max-height: 77px;
                }
            }

            .sub-article {
                margin-left: 10px;
                width: 160px;
                height: 200px;

                img {
                    width: 160px;
                    height: 90px;
                }

                .title {
                    width: 160px;
                    height: 60px;
                    padding: 0;
                }
            }

            .article {
                width: 140px;
                margin: 10px 11px 0 0;

                img {
                    width: 140px;
                    height: 80px;
                }

                .title {
                    width: 140px;
                }
            }

            .no-right-margin {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .category-page {
        padding: 10px;

        img {
            aspect-ratio: 5 / 3;
        }

        .wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;

            .main-article {
                width: 100%;
                height: auto;
                flex-direction: column;
                margin: 0;

                img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    margin-top: 10px;
                    width: 100%;
                }

                .descr {
                    width: 100%;
                    height: auto;
                    max-height: 140px;
                    // padding: 0;
                }
            }

            .sub-article {
                width: 100%;
                height: 100%;
                margin: 10px 0 0 0;

                img {
                    width: 100%;
                    height: 100%;
                }

                .title {
                    width: 100%;
                    height: 84px;
                }
            }

            .article {
                width: 50%;
                margin: 0 0 0 0;
                height: 275px;

                img {
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                }

                .title {
                    padding: 0px 10px 0 10px;
                    max-height: 105px;
                    height: auto;
                    width: 100%;
                    padding: 0 10px 5px 10px;
                }
            }
        }
    }
}