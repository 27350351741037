/* Add this to your CSS file or use a CSS-in-JS solution */

.error-modal {
    // position: fixed;
    // top: 0;
    // left: 0;

    // width: 100%;
    // height: 100%;
    // display: flex;
    // align-items: center;

    // justify-content: center;
    // z-index: 9999;
    // background-color: transparent;

    // pointer-events: none;




    position: fixed;
    // top: 60px;
    top: -100%;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 9999;
    background-color: transparent;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;

}

.error-modal_active {
    top: 60px;
}

.modal-content {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    max-width: 80%;
    border: 1px solid #000;
    width: 500px;
    /* Limit the width of the modal */
    word-wrap: break-word;
}

.close-button {
    // position: absolute;
    // top: 10px;
    // right: 10px;
    background-color: #2E9CB4;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}