.footer-component {


    background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
    height: 165px;
    // position: absolutel;
    bottom: 0;



    .wrapper {
        padding: 10px 0 0 0;
        // align-items: center;
        margin: auto;
        width: 1100px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .rough {

            display: flex;
            flex-direction: row;

            .links {
                width: 150px;
                // justify-content: space-around;
                display: flex;
                flex-direction: column;
                margin-top: 14px;

                a {
                    margin-top: 5px;
                    text-decoration: none;
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 0.75px;
                }
            }

            .rough-subsection {
                display: flex;
                flex-direction: column;
                width: 150px;

                .title {
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    margin: 5px 0;
                }

                a {
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    margin-top: 2px;
                }
            }
        }

        .footer-info {
            display: flex;
            // align-items: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .logo {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                background: var(--Blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                // color: #fff;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                text-align: center;

                font-family: Montserrat;
                font-size: 34px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;


                span {
                    display: flex;
                    justify-content: flex-end;
                    // justify-self: flex-end;
                    // color: #FFF;
                    -webkit-text-fill-color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 0.7px;
                }
            }

            .sub-logo {
                margin-top: 20px;
                background: var(--Blue-accent, linear-gradient(180deg, #E0D9E2 0%, #DEF0F2 28.13%, #88D7F0 75%, #76A0E0 100%));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                text-align: center;
                font-family: Raleway;
                font-size: 16px;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
                letter-spacing: 5.44px;
            }

            .links {
                margin-top: 20px;

                display: flex;
                flex-direction: row;
                // justify-content: center;
                // align-items: center;
                font-size: 13px;
                font-weight: 200;
                letter-spacing: 0.91px;

                .divider {
                    width: 1px;
                    height: 40px;
                    background-color: #fff;
                    margin: 0 5px;

                }

                .link:first-child {
                    text-align: end;
                }



                .link {
                    display: flex;
                    flex-direction: column;

                    a:last-child {
                        margin-top: 2px;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        // margin: 2px 0;

                    }
                }
            }
        }

        .polished {
            display: flex;
            flex-direction: row;

            .links {
                display: flex;
                flex-direction: column;
                margin-top: 14px;
                width: 150px;

                a {
                    margin-top: 5px;
                    text-decoration: none;
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 0.75px;
                }
            }

            .polished-subsection {
                display: flex;
                flex-direction: column;
                width: 150px;

                .title {
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: 1.2px;
                    margin: 5px 0;
                }

                a {
                    color: #FFF;
                    text-align: center;
                    font-family: Raleway;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 200;
                    line-height: normal;
                    letter-spacing: 0.75px;
                    text-decoration: none;
                    margin-top: 2px;

                }
            }
        }
    }

    .wrapper-mobile {
        display: none;
    }
}



@media (max-width: 1200px) {
    .footer-component {
        .wrapper-mobile {
            display: none;
        }

        .wrapper {
            width: 980px;

        }
    }
}

@media (max-width: 991px) {

    .footer-component {
        height: auto;

        .wrapper {
            display: none;
        }

        .wrapper-mobile {
            display: block;
            width: 750px;
            flex-direction: column;
            // justify-content: center;

            // justify-content: baseline;
            .rough-polished {
                display: flex;
                justify-content: center;

                .rough {
                    display: flex;
                    flex-direction: column;
                }

                .polished {
                    display: flex;
                    flex-direction: column;
                }
            }

            .links {
                margin-top: 20px;

                display: flex;
                flex-direction: row;
                // justify-content: center;
                // align-items: center;
                font-size: 13px;
                font-weight: 200;
                letter-spacing: 0.91px;
                justify-content: center;

                .divider {
                    width: 1px;
                    height: 40px;
                    background-color: #fff;
                    margin: 0 5px;

                }

                .link:first-child {
                    text-align: end;
                }



                .link {
                    display: flex;
                    flex-direction: column;

                    a:last-child {
                        margin-top: 2px;
                    }

                    a {
                        color: white;
                        text-decoration: none;
                        // margin: 2px 0;

                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .footer-component {

        .wrapper {
            display: none;
        }

        .wrapper-mobile {
            display: block;
            width: 300px;
        }

        // .wrapper {
        //     width: 300px;
        // }
    }
}