.errorPage {
    display: flex;
    flex-direction: column;

    // justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eeecec2c;
    padding: 35px;
}

.errorMessage {
    margin-top: 40px;
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    // box-shadow: 100px 0px 100px rgba(0, 0, 0, 0.1);
}