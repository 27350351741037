.create-external-page {

    padding: 10px;
    ;

    h1 {
        font-size: 54px;
        font-weight: 400;
        margin: 30px 0 0 0;

    }

    .input-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        input {
            border: 1px solid #000;
            border-radius: 4px;
            margin-top: 15px;
            width: 330px;
            height: 40px;
            padding: 10px;
        }
    }

    .type {
        margin-top: 12px;
    }

    button {
        margin-top: 15px;
        color: #FFF;
        text-align: center;
        font-family: Raleway;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.54px;
        border-radius: 4px;
        background: var(--footer-1, linear-gradient(180deg, #545763 0%, #292D30 12.5%, #000 100%));
        height: 40px;
        width: 190px;
        cursor: pointer;
    }

    .success {
        margin-top: 10px;
        // color: #7edd1e;
        color: #2E9CB4;
    }
}